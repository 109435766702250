<script lang="ts" setup>
interface Props {
    to: any
}

const props = defineProps<Props>()
const route = useRoute()
const localeTo = computed(() => {
    const query = { region: route.query.region }
    const localeRoute = { ...route, query }
    if (props.to && typeof props.to === 'string') {
        return {
            path: props.to,
            query
        }
    }

    if (typeof props.to === 'object') {
        return {
            ...props.to,
            query
        }
    }

    return localeRoute
})

//
</script>

<template>
    <NuxtLinkLocale :to="localeTo">
        <slot></slot>
    </NuxtLinkLocale>
</template>

<style lang="scss" scoped></style>
